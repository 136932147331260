<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="1500"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <a-card :bordered="false" class="card-area">
      <div class="search">
        <!-- 搜索区域 -->
        <div class="items" style="flex: none">
          <div class="one-item">
            <a-select
                class="input"
                v-model="queryParams.type"
                allowClear
                placeholder="类型(反馈/投诉)"
            >
              <a-select-option
                  v-for="item in typeList"
                  :value="item.id"
                  :key="item.id"
              >{{ item.typeName }}
              </a-select-option
              >
            </a-select>
          </div>
          <!-- <div class="one-item" style="padding-left: 10px">
            <a-input
              class="input"
              v-model="queryParams.username"
              placeholder="用户名"
              allowClear
            />
          </div>

          <div class="one-item" style="padding-left: 10px">
            <a-input
              class="input"
              v-model="queryParams.contact"
              placeholder="联系方式"
              allowClear
            />
          </div> -->
        </div>
        <div class="operator">
          <a-button type="primary" @click="search(1)">查询</a-button>
        </div>
      </div>
      <a-table
          ref="TableInfo"
          size="small"
          :bordered="true"
          :columns="columns"
          rowKey="id"
          :dataSource="dataSource"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
          :scroll="{ x: 1000 }"
      >
        <template slot="headPortrait" slot-scope="text, record">
          <div v-if="text.avatar" class="img-list">
            <el-image
                style="width: 40px; height: 40px"
                :src="(text.avatar || '') | fullImgPath"
                fit="contain"
                :preview-src-list="(text.avatar || '').split(',')"
            />
          </div>
          <div v-else style="color: #cdcdcd">
            <a-tag color="green">无</a-tag>
          </div>
        </template>
        <template slot="typeStatus" slot-scope="text, record">
          <!-- <div>{{ record.type }}</div> -->
          <a-tag :color="record.type | dictName('typeStatus', 'color')">{{
              record.type | dictName("typeStatus")
            }}
          </a-tag>
        </template>
        <template slot="images" slot-scope="text, record">
          <div v-if="text.images.length > 0" class="img-list">
            <el-image
                style="width: 40px; height: 40px"
                :src="text.images[0] | fullImgPath"
                fit="contain"
                :preview-src-list="text.images || []"
            />
          </div>
          <div v-else style="color: #cdcdcd">无图片信息</div>
        </template>
        <template slot="content" slot-scope="text, record">
          <a-tooltip>
            <template slot="title"> {{ text.content }}</template>
            <div>
              {{
                text.content.length > 30
                    ? text.content.slice(0, 50) + "..."
                    : text.content
              }}
            </div>
          </a-tooltip>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button type="danger" size="mini" @click="deleteOne(record)">删除</a-button>
        </template>
      </a-table>
    </a-card>
  </a-modal>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";

export default {
  name: "feedBackModal",
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "feedback/list",
      visible: false,
      title: "",
      confirmLoading: false,
      queryParams: {
        username: "",
        content: "",
      },
      typeList: [
        {
          id: "0",
          typeName: "反馈",
        },
        {
          id: "1",
          typeName: "投诉",
        },
      ],
    };
  },
  computed: {
    columns() {
      this.dataSource = this.dataSource.map((item) => {
        try {
          item.images = JSON.parse(item.images) || [];
        } catch (error) {
          item.images = [];
        }
        return item;
      });
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "头像",
          width: 100,
          align: "center",
          scopedSlots: {customRender: "headPortrait"},
        },
        {
          title: "用户名",
          align: "center",
          width: 150,
          dataIndex: "username",
          //   scopedSlots: { customRender: "amount" },
        },
        {
          title: "类型",
          width: 100,
          align: "center",
          scopedSlots: {customRender: "typeStatus"},
        },
        {
          title: "联系方式",
          align: "center",
          width: 150,
          dataIndex: "contact",
          //   scopedSlots: { customRender: "amount" },
        },
        {
          title: "认证公司",
          align: "center",
          width: 150,
          dataIndex: "companyByUser",
          //   scopedSlots: { customRender: "amount" },
        },
        {
          title: "图片凭证",
          width: 100,
          align: "center",
          scopedSlots: {customRender: "images"},
        },
        {
          title: "反馈/投诉内容",
          align: "center",
          scopedSlots: {customRender: "content"},
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        // {}
      ];
    },
  },
  methods: {
    showModal(record) {
      this.visible = true;
      this.queryParams.postId = record.id;
      this.search();
      this.title = "帖子ID：" + record.id + "反馈与投诉";
    },
    handleOk() {
      //   this.$emit("success");
      this.queryParams = {};
      this.visible = false;
    },
    handleCancel() {
      //   this.$emit("success");
      this.queryParams = {};
      this.visible = false;
    },
    deleteOne(record) {
      const _this = this
      this.$confirm({
        title: "删除确认",
        content: "您确认要删除当前记录吗?",
        centered: true,
        onOk() {
          _this.$delete("/feedback/delete/" + record.id).then(() => {
            _this.$message.success('删除成功')
            _this.search()
          })
        }
      });
    }
  },
};
</script>
