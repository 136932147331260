<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 100px">
          <a-input
              class="input"
              v-model="queryParams.id"
              placeholder="帖子ID"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 100px">
          <a-select
              class="input"
              v-model="queryParams.status"
              placeholder="状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict[statusDictName]" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 100px" v-if="postType=='post'">
          <a-select
              class="input"
              v-model="queryParams.type"
              placeholder="类型"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict['postType']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 100px" v-if="postType=='post'">
          <a-select
              class="input"
              v-model="queryParams.isShow"
              placeholder="显示"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in showStatusList" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 80px" v-if="postType=='post'">
          <a-select
              class="input"
              v-model="queryParams.isTop"
              placeholder="推荐"
              @change="search(1)"
              allowClear
          >
            <a-select-option :key="1">是</a-select-option>
            <a-select-option :key="0">否</a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 120px">
          <a-input
              class="input"
              v-model="queryParams.username"
              placeholder="用户名"
              allowClear
          />
        </div>
        <div class="one-item" style="padding-left: 10px;width:120px">
          <a-input
              class="input"
              v-model="queryParams.company"
              placeholder="公司名称"
              allowClear
          />
        </div>
        <div class="one-item" style="padding-left: 10px;width:120px">
          <a-input
              class="input"
              v-model="queryParams.title"
              placeholder="标题"
              allowClear
          />
        </div>
        <div class="one-item" style="padding-left: 10px;width:120px">
          <a-input
              class="input"
              v-model="queryParams.content"
              placeholder="内容"
              allowClear
          />
        </div>
        <!--        <div class="one-item" style="padding-left: 10px;width:120px" v-if="postType=='post'">-->
        <!--          <a-input-->
        <!--              class="input"-->
        <!--              v-model="queryParams.circle"-->
        <!--              placeholder="圈子"-->
        <!--              allowClear-->
        <!--          />-->
        <!--        </div>-->
        <div class="one-item" style="padding-left: 10px;width:120px" v-if="postType=='post'">
          <a-input
              class="input"
              v-model="queryParams.topic"
              placeholder="话题"
              allowClear
          />
        </div>
        <div class="one-item" style="padding-left: 10px;width:120px" v-if="postType=='post'">
          <a-input
              class="input"
              v-model="queryParams.city"
              placeholder="城市"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 120px" v-if="postType=='post'">
          <a-select
              class="input"
              v-model="queryParams.sourceType"
              placeholder="来源"
              @change="search(1)"
              allowClear
          >
            <a-select-option :key="1">用户输入</a-select-option>
            <a-select-option :key="2">线下导入</a-select-option>
            <a-select-option :key="3">后台输入</a-select-option>
          </a-select>
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        <a-button type="danger" v-has-permission="'post:addManual'" @click="add(1)" v-if="postType=='post'">新增避坑手册
        </a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="auditTitle">
        <a-icon type="smile-o"/>
        Name
      </template>
      <template slot="avatar" slot-scope="text, record">
        <div v-if="record.avatar" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.avatar || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.avatar || '').split(',')"
          />
        </div>
      </template>
      <template slot="arrears" slot-scope="text, record">
        <div>{{ record.username || "" }}</div>
        <div>{{ record.mobile || "" }}</div>
      </template>
      <template slot="rateJson" slot-scope="text, record">
        <div>
          <div style="color:#FF9900;">{{ record.rateJson.rateValue }}</div>
          <a-rate v-model="record.rateJson.rateValue" disabled allowHalf/>
        </div>
      </template>
      <template slot="isShow" slot-scope="text, record">
        <div title="点击设置显示隐藏" @click.stop="hidePost(record)" style="cursor: pointer">
          <a-tag :color="record.isShow | dictName('isShow', 'color')" style="cursor: pointer">
            <span>{{ record.isShow| dictName("isShow") }}</span>
          </a-tag>
          <a-icon type="edit" style="margin-left:-5px;color:rgb(24, 144, 255)"/>
        </div>
      </template>
      <template slot="isPublic" slot-scope="text, record">
        <a-tag :color="record.isPublic | dictName('isPublic', 'color')" style="cursor: pointer">
          <span>{{ record.isPublic| dictName("isPublic") }}</span>
        </a-tag>
      </template>
      <template slot="debtStatus" slot-scope="text, record">
        <a-tag v-if="text==1" color="#FF9900">已撤回</a-tag>
        <a-tag v-else color="green">正常</a-tag>
      </template>
      <template slot="postType" slot-scope="text, record">
        <a-tag :color="record.type | dictName('postType', 'color')" style="cursor: pointer">
          <span>{{ record.type| dictName("postType") }}
          </span>
        </a-tag>
        <a-tag v-if="record.subType" color="red">
          <span>{{ record.subType }}</span>
        </a-tag>
      </template>
      <template slot="readStatus" slot-scope="text, record">
        <a-tag :color="record.readStatus | dictName('readStatus', 'color')" style="cursor: pointer">
          <span>{{ record.readStatus| dictName("readStatus") }}</span>
        </a-tag>
      </template>
      <template slot="status" slot-scope="text, record">
        <div title="点击审核" @click.stop="auditPost(record)" style="cursor: pointer">
          <a-tag :color="record.status | dictName(statusDictName, 'color')" style="cursor: pointer">
            <span v-if="record.status==0">修改</span>
            <span v-else>{{ record.status| dictName(statusDictName) }}</span>
          </a-tag>
          <a-tag v-if="record.status==0" color="#FF9900" style="cursor: pointer;margin-top:5px;"
                 @click.stop="auditPost(record,1)">审核
          </a-tag>
        </div>
      </template>
      <template slot="postTop" slot-scope="text, record">
        <a-switch :checked="record.postTop==1" @change="changeTopState(record)" :loading="showLoading"/>
      </template>
      <template slot="feedBackCount" slot-scope="text, record">
        <div
            class="textClick"
            :style="{ color: textColor }"
            @click="feedBackModal(text)"
        >
          {{ record.feedBackCount }}
        </div>
      </template>
      <template slot="circleName" slot-scope="text, record">
        <div v-if="record.circleName" style="color:rgb(24, 144, 255)">
          @{{ record.circleName }}
        </div>
        <div v-if="record.topicName">
          #{{ record.topicName }}#
        </div>
      </template>
      <template slot="anonymousMess" slot-scope="text, record">
        <a-tooltip>
          <template slot="title"> {{ text }}</template>
          <div class="content" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ text }}</div>
          <a-tag color="blue" style="cursor: pointer;margin-top:5px;"
                 @click.stop="copyMessage(record)">复制
          </a-tag>
        </a-tooltip>
      </template>
      <template slot="comment" slot-scope="text, record">
        <div
            class="textClick"
            :style="{ color: textColor }"
            @click="opencommentModal(text)"
        >
          {{ record.commentCount }}
        </div>
      </template>
      <template slot="prizeCount" slot-scope="text, record">
        <div
            class="textClick"
            :style="{ color: textColor }"
            @click="changePrizeCount(record)"
        >
          {{ record.collectionCount || 0 }}
        </div>
      </template>
      <template slot="payStatus" slot-scope="text, record">
        <div v-if="record.json&&record.json.payStatus==2">
          <a-tag color="green">已支付{{ record.json.amount }}</a-tag>
        </div>
        <a-tag v-else color="red">未支付</a-tag>
      </template>
      <template slot="content" slot-scope="text, record">
        <a-tooltip>
          <template slot="title"> {{ record.content }}</template>
          <div class="content">{{ record.content }}</div>
        </a-tooltip>
        <div v-if="record.media.length > 0" class="img-list">
          <video v-if="record.media[0].url"
                 controls autoplay
                 style=" max-height: 100px"
                 :src="record.media[0].url"
                 :poster="record.media[0].cover"
          />
          <template v-else>
            <el-image
                style="width: 40px; height: 40px"
                v-for="(oneImg,index) in record.media" :key="index"
                :src="oneImg | fullImgPath"
                fit="contain"
                :preview-src-list="record.media || []"
            />
          </template>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-dropdown
            v-hasAnyPermission="['post:view', 'post:edit', 'post:delete']"
        >
          <a-menu slot="overlay">
            <a-menu-item key="modify" @click="modify($event, record)"
                         v-hasPermission="'post:edit'">
              <a-icon type="setting"/>
              修改
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item key="comment" @click="opencommentModal(record)"
                         v-hasPermission="'post:edit'">
              <a-icon type="setting"/>
              评论
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="delete"
                style="color: red"
                v-hasPermission="'post:delete'"
                @click="getDelete($event, record)"
            >
              <a-icon type="delete"/>
              删除
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="prize"
                style="color: red"
                v-hasPermission="'post:edit'"
                @click="modifyPrizeTask($event, record)"
            >
              <a-icon type="like"/>
              自动点赞
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="auto-comment"
                style="color: red"
                v-hasPermission="'post:edit'"
                @click="modifyCommentTask($event, record)"
            >
              <a-icon type="like"/>
              自动评论
            </a-menu-item>
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>

    <comment-modal ref="commentModal" @success="editSuccess"/>
    <feed-back-modal ref="feedBackModal"/>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
    <post-audit-modal ref="postAuditModal" @success="editSuccess"></post-audit-modal>
    <post-hide-modal ref="postHideModal" @success="editSuccess"></post-hide-modal>
    <post-prize-task-modal ref="postPrizeTaskModal"/>
    <post-comment-task-modal ref="postCommentTaskModal"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import commentModal from "./commentModal.vue";
import feedBackModal from "./feedBackModal.vue";
import modifyModal from "./modifyModal.vue";
import PostAuditModal from "./postAuditModal";
import PostHideModal from "./postHideModal";
import {DICT} from "@/utils/dict";
import {MessageBox} from "element-ui";
import PostPrizeTaskModal from "./postPrizeTaskModal";
import PostCommentTaskModal from "./postCommentTaskModal";

const clipboardy = require('clipboardy');

export default {
  name: "PostList",
  mixins: [ListMixin],
  components: {
    PostCommentTaskModal,
    PostPrizeTaskModal, PostAuditModal, commentModal, feedBackModal, modifyModal, PostHideModal
  },
  props: {
    postType: {
      type: String,
      default: 'post'
    }
  },
  data() {
    return {
      listGetUrl: "post/listByAdmin",
      listMethod: "postJson",
      showLoading: false
    };
  },
  computed: {
    // postType() { //是否匿名真心话
    //   console.log('this.$route.path=' + this.$route.path)
    //   if (this.$route.path.indexOf('messList') > 0) {
    //     return 'anonymous';
    //   } else if (this.$route.path.indexOf('boxMailList') > 0) {
    //     return 'mail';
    //   }
    //   return 'post';
    // },
    textColor() {
      return this.$store.state.setting.color;
    },
    statusDictName() {
      return 'postStatus';
    },
    showStatusList() {
      return DICT['isShow']
    },
    columns() {
      // let {sortedInfo, filteredInfo} = this;
      // sortedInfo = sortedInfo || {};
      // filteredInfo = filteredInfo || {};
      let colList = [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "头像",
          width: 60,
          align: "center",
          dataIndex: "avatar",
          scopedSlots: {customRender: "avatar"},
        },
        {
          title: "用户名",
          width: 120,
          align: "center",
          scopedSlots: {customRender: "arrears"},
          dataIndex: "username",
        },
        {
          title: "公司名称",
          width: 120,
          align: "center",
          dataIndex: "company",
          columnType: ['post', 'anonymous']
        },
        {
          title: "城市",
          align: "center",
          width: 80,
          dataIndex: "city",
          columnType: ['post']
        },

        {
          title: "类型",
          align: "center",
          width: 90,
          dataIndex: "type",
          scopedSlots: {customRender: "postType"},
        },
        {
          title: "来源",
          width: 80,
          align: "center",
          dataIndex: "sourceType",
          columnType: ['post'],
          customRender: (text, row, index) => {
            if (text == 2) {
              return <a-tag color="pink">导入</a-tag>
            } else if (text == 3) {
              return <a-tag color="green">输入</a-tag>
            } else {
              return <a-tag color="#FF9900">用户</a-tag>
            }

          }
        },
        {
          title: "分类",
          align: "center",
          width: 90,
          dataIndex: "cateName",
          columnType: ['post']
        },
        {
          title: "圈子/话题",
          align: "center",
          width: 120,
          dataIndex: "circleName",
          scopedSlots: {customRender: "circleName"},
          columnType: ['post']
        },
        // {
        //   title: "话题",
        //   width: 120,
        //   align: "center",
        //   dataIndex: "topicTitle",
        // },
        // {
        //   title: "图片信息",
        //   width: 80,
        //   align: "center",
        //   scopedSlots: {customRender: "images1"},
        // },
        {
          title: "接收方手机号",
          width: 120,
          align: "center",
          dataIndex: 'anonymousMobile',
          columnType: ['anonymous', 'boxMail']
        },
        {
          title: "标题",
          width: 150,
          align: "center",
          dataIndex: "title",
        },
        {
          title: "内容",
          width: 250,
          align: "center",
          scopedSlots: {customRender: "content"},
        },
        {
          title: "审核状态",
          width: 80,
          align: "center",
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "置顶|推荐",
          width: 80,
          align: "center",
          scopedSlots: {customRender: "postTop"},
          columnType: ['post']
        },
        {
          title: "显示状态",
          width: 100,
          align: "center",
          dataIndex: "isShow",
          scopedSlots: {customRender: "isShow"},
          columnType: ['post']
        },
        // {
        //   title: "是否公开",
        //   width: 80,
        //   align: "center",
        //   dataIndex: "isPublic",
        //   scopedSlots: {customRender: "isPublic"},
        //   columnType: 'anonymous'
        // },
        {
          title: "撤回状态",
          width: 80,
          align: "center",
          dataIndex: "debtStatus",
          scopedSlots: {customRender: "debtStatus"},
          columnType: ['post']
        },
        {
          title: "反馈/投诉",
          align: "center",
          width: 80,
          scopedSlots: {customRender: "feedBackCount"},
          columnType: ['post']
          // customRender: (text, row, index) => {
          //   return row.collectionCount || 0;
          // },
        },
        {
          title: "短信内容",
          width: 120,
          align: "center",
          dataIndex: 'anonymousMess',
          scopedSlots: {customRender: "anonymousMess"},
          columnType: ['anonymous']
        },
        {
          title: "支付状态",
          width: 100,
          align: "center",
          scopedSlots: {customRender: "payStatus"},
          columnType: ['anonymous']
        },
        {
          title: "阅读状态",
          width: 100,
          align: "center",
          dataIndex: "readStatus",
          scopedSlots: {customRender: "readStatus"},
          columnType: ['anonymous', 'boxMail']
        },
        {
          title: "浏览数",
          align: "center",
          width: 60,
          dataIndex: "readCount",
          columnType: ['post']
        },
        {
          title: "评论数",
          align: "center",
          width: 60,
          scopedSlots: {customRender: "comment"},
          columnType: ['post']
        },
        {
          title: "点赞数",
          align: "center",
          width: 60,
          // dataIndex: "collectionCount",
          scopedSlots: {customRender: "prizeCount"},
          // customRender: (text, row, index) => {
          //   return row.collectionCount || 0;
          // },
          columnType: ['post']
        },
        {
          title: "评分",
          width: 140,
          align: "center",
          dataIndex: "rateJson",
          ellipsis: true,
          scopedSlots: {customRender: "rateJson"},
          columnType: ['post']
        },
        {
          title: "标签",
          width: 220,
          align: "center",
          dataIndex: "tagJson",
          ellipsis: true,
          columnType: ['post']
        },
        {
          title: "发布时间",
          width: 150,
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "地址",
          align: "center",
          width: 330,
          columnType: ['post'],
          // scopedSlots: { customRender: "address" },
          customRender: (text, row, index) => {
            let str =
                (row.province || "") +
                (row.city || "") +
                (row.area || "") +
                (row.town || "") +
                (row.address || "");
            return str;
          },
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        // {}
      ];
      return colList.filter(v => !v.columnType || v.columnType.includes(this.postType));
    }
  },

  mounted() {
    this.search(1);
  },
  methods: {
    // 反馈与投诉
    feedBackModal(record) {
      if (record.feedBackCount == 0 || !record.feedBackCount) {
        this.$message.error("该帖子暂无反馈与投诉");
        return;
      }
      this.$refs.feedBackModal.showModal(record);
    },
    // 评论数
    opencommentModal(record) {
      if (record.commentCount == 0 || !record.commentCount) {
        this.$message.error("该帖子暂无评论");
        return;
      }
      this.$refs.commentModal.showModal(record);
    },
    changePrizeCount(record) {
      const that = this;

      MessageBox.prompt('请输入点赞调整数量（-表示减少，其他表示增加）', '调整帖子点赞数量', {
        inputValue: '',
        inputPlaceholder: '输入调整数量',
      }).then(({value}) => {
        if (value) {
          that.showLoading = true;
          that.$postJson("post/updatePost", {id: record.id, adjustPrizeCount: value}).then(() => {
            that.showLoading = false;
            record.collectionCount = (record.collectionCount || 0) + (+value);
            that.$message.success("点赞数修改成功");
            // this.search();
          });
        }
      })
    },
    editSuccess() {
      this.search();
    },
    beforeFetch(params) {
      if (this.postType === 'anonymous') {
        params.type = 15;
      } else if (this.postType === 'boxMail') {
        params.type = 16;
      } else if (!this.queryParams.type) {
        //帖子类型：1-普通，2--审核专用，5-曝光公司，6--社区帖子，10--法援专区，
        // 11-面试经历，12-招聘内推，13--企业建议，14--知识库，15--匿名传话筒,16--老板信箱',
        // 17--点评房东，18--消费投诉，19--曝薪资，20--问公司，21--聊职业，22--聊行业
        params.typeList = [1, 2, 5, 6, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32];
      }
    },
    afterFetch(data) {
      debugger
      if (!data.records) {
        return;
      }
      try {
        data.records.forEach((item) => {
          try {
            item.media = JSON.parse(item.media) || [];
          } catch (error) {
            item.media = [];
          }
          if (item.otherJson) {
            //设置模板消息
            let json = typeof item.otherJson === 'string' ? JSON.parse(item.otherJson) : item.otherJson;
            item.json = json;
            item.anonymousMobile = json.mobile;
            item.anonymousMess = '【诚信点评】匿名真心话：' + (json.title ? '【' + json.title + '】' : '')
                + '【' + item.title?.substring(0, 20) + '】'
                + '验证码：' + (item.status == 0 ? '审核后才会生成验证码' : item.viewPass)
                + '。以上内容是好友委托「诚信点评」发送，点击链接https://chengxin.mxklchina.com/messView.html，进入小程序后凭验证码查看'
          } else {
            item.json = {};
          }
          if (item.rateJson) {
            //设置模板消息
            let json = typeof item.rateJson === 'string' ? JSON.parse(item.rateJson) : item.rateJson;
            item.rateJson = json;
          } else {
            item.rateJson = {};
          }
          // if (item.tagJson) {
          //   //设置模板消息
          //   let json = typeof item.tagJson === 'string' ? JSON.parse(item.tagJson) : item.tagJson;
          //   item.tagJson = json;
          // }
          return item;
        });
      } catch (e) {
        console.error(e);
      }
    },
    copyMessage(record) {
      clipboardy.write(record.anonymousMess);
      this.$message.success('内容已复制');
    },
    changeShowState(record) {
      let that = this;
      let data = {
        id: record.id,
        isShow: record.isShow == 1 ? 0 : 1,
      };
      that.showLoading = true;
      that.$post("post/isShow", data).then(() => {
        that.showLoading = false;
        let mes =
            record.isShow != 1
                ? "ID：" + record.id + "显示成功"
                : "ID：" + record.id + "隐藏成功";
        that.$message.success(mes + "请前往小程序查看");
        this.search();
      });
    },
    changeTopState(record) {
      let that = this;
      let data = {
        id: record.id,
        isTop: record.postTop == 1 ? 0 : 1,
      };
      that.showLoading = true;
      that.$post("post/isTop", data).then(() => {
        that.showLoading = false;
        let mes =
            record.postTop != 1
                ? "ID：" + record.id + "置顶成功"
                : "ID：" + record.id + "取消置顶成功";
        that.$message.success(mes + "请前往小程序查看");
        this.search();
      });
    },
    modify(e, record) {
      this.$refs.modifyModal.showModal(record);
    },
    add() {
      this.$refs.modifyModal.showModal({type: 14}, 'new');
    },
    auditPost(record, isFast) {

      if (this.$helper.hasPermission('post:audit')) {
        if (isFast) {
          this.$refs.postAuditModal.showModal(record);
        } else {
          this.$refs.modifyModal.showModal(record);
        }
      } else {
        this.$message.warn("对不起，您没有审核权限")
      }
    },
    hidePost(record) {
      if (this.$helper.hasPermission('post:audit')) {
        this.$refs.postHideModal.showModal(record);
      } else {
        this.$message.warn("对不起，您没有审核权限（显示/隐藏同审核权限）")
      }
    },
    getDelete(e, record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除【ID：" + record.id + "】帖子吗？！",
        centered: true,
        onOk() {
          that.$post("post/deletePost", {id: record.id}).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
    modifyPrizeTask(e, record) {
      this.$refs.postPrizeTaskModal.showModal(record, 'post');
    },
    modifyCommentTask(e, record) {
      this.$refs.postCommentTaskModal.showModal(record, 'post');
    }
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}

.content {
  max-height: 100px;
  overflow-y: auto;
}
</style>
